import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import { mediaQueries } from "./breakpoints"
import { Container } from "./Container"
import Header from "./Header"

import jumbotron from "../images/jumbotron.jpg"
import jumbotronMobile from "../images/jumbotron-mobile.jpg"
import CTA from "./CTA"
import moment from 'moment'

const Jumbotron = styled("div")`
  position: relative;
  margin-bottom: 3rem;
  background-size: cover;
  background-image: url(${jumbotronMobile});
  background-position: center right;
  ${mediaQueries.medium(
    css`
      background-image: url(${jumbotron});
      background-position: center center;
  `)}
  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(32,29,29,.7525385154061625) 50%, rgba(32,29,29,0.1) 100%);
    ${mediaQueries.medium(
      css`
        background: linear-gradient(90deg, rgba(32,29,29,.7525385154061625) 25%, rgba(32,29,29,0) 50%);
      `
    )}
  }
`

const JumbotronContent = styled("div")`
  position: relative;
  z-index: 2;
  padding-top: 1rem;
  padding-bottom: 3.5rem;
  ${mediaQueries.medium(
    css`
      padding-top: 5rem;
      padding-bottom: 7.5rem;
    `
  )}

  h1 {
    text-shadow: 0 0 10px rgba(32, 29, 29, 1);
  }

  h2 {
    text-shadow: 0 0 10px rgba(32, 29, 29, 1);
  }

  p {
    margin-bottom: .5em;
    font-size: 1.125rem;
    text-shadow: 0 0 10px rgba(32, 29, 29, 1);
  }
`

const JumbotronButtons = styled("div")`
  & > div {
    justify-content: flex-start;
    margin-left: calc(var(--gutterWidth) / 2 * -1);
  }
`

const HeadingTheme = styled("h2")`
  span {
    text-transform: uppercase;
  }
`

const JumbotronParagraph = styled("p")`
  color: var(--yellow);
`

class JumbotronSection extends React.Component {

  render() {
    const { data } = this.props
    const { edges: events } = data.allMarkdownRemark

    return (
      <Jumbotron>
        <Header />
        <Container jumbotron>
          <JumbotronContent>
            <h1>MISESOVA AKADEMIE <br/>LIBERÁLNÍHO INSTITUTU</h1>
            {events && events.length ? (
              <>
                {events &&
                  events.map(({ node: event }) => (
                    <div key={event.id}>
                      {((event.frontmatter.theme) && (
                        <HeadingTheme>Téma: <span>{event.frontmatter.theme}</span></HeadingTheme>
                      ))}
                      <p>{moment(event.frontmatter.date).format(`D. M.`)} –⁠ {moment(event.frontmatter.dateEnd).format(`D. M. YYYY`)} - <a target="_blank" href={event.frontmatter.placeURL}>{event.frontmatter.placeName}</a></p>
                      {((event.frontmatter.event1price) && (event.frontmatter.event2price)) && (
                        <p>
                          {event.frontmatter.event1price &&
                          event.frontmatter.event1price.length ? (
                          <>
                            MALI: {event.frontmatter.event1price} korun
                          </>
                          ) : null }
                          &nbsp;|&nbsp;
                          {event.frontmatter.event2price &&
                          event.frontmatter.event2price.length ? (
                          <>
                            MSLI: {event.frontmatter.event2price} korun
                          </>
                          ) : null }
                      </p>
                      )}


                      <JumbotronButtons>
                        <CTA />
                      </JumbotronButtons>
                      {event.frontmatter.registrationStatus.trim() ===
                        "close" && (
                        <JumbotronParagraph>Přihlášky jsou uzavřeny. Těšíme se na vás!</JumbotronParagraph>
                      )}
                      {event.frontmatter.registrationStatus.trim() ===
                        "postponed" && (
                        <JumbotronParagraph>Událost byla odložena</JumbotronParagraph>
                      )}
                      {event.frontmatter.registrationStatus.trim() ===
                        "canceled" && (
                        <JumbotronParagraph>Událost byla zrušena</JumbotronParagraph>
                      )}
                      {event.frontmatter.registrationOpeningDate && event.frontmatter.registrationStatus.trim() ===
                        "preparation" && (
                        <JumbotronParagraph>Přihlášky na nový ročník otevřeme {moment(event.frontmatter.registrationOpeningDate).format(`D. M. YYYY`)}</JumbotronParagraph>
                      )}
                      {!event.frontmatter.registrationOpeningDate && event.frontmatter.registrationStatus.trim() ===
                        "preparation" && (
                        <JumbotronParagraph>Již brzy otevřeme přihlášky na nový ročník.</JumbotronParagraph>
                      )}
                    </div>
                  )
                )}
              </>
            ) : <JumbotronParagraph>Připravujeme pro vás další ročník.</JumbotronParagraph> }
          </JumbotronContent>
        </Container>
      </Jumbotron>
    )
  }
}

JumbotronSection.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query JumbotronSectionQuery {
        allMarkdownRemark(
          limit: 1
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              registrationStatus: { in: [ "preparation", "open", "close", "postponed", "canceled" ]}
              templateKey: { eq: "event" }
            }
          }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                templateKey
                event1formURL
                event2formURL
                placeName
                placeURL
                event1price
                event2price
                registrationStatus
                registrationOpeningDate
                date
                dateEnd
                theme
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <JumbotronSection data={data} count={count} />}
  />
)

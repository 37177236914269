import React from "react"
import Layout from "../components/Layout"
import JumbotronSection from "../components/Jumbotron"
import SectionAbout from "../components/SectionAbout"
import EventSpeakers from "../components/EventSpeakers"
import EventPrograms from "../components/EventPrograms"
import HistorySection from "../components/HistorySection"
import SectionDocuments from "../components/SectionDocuments"
import Graduates from "../components/Graduates"
import PartnersSection from '../components/PartnersSection'

if (typeof window !== "undefined") {
  /* tslint:disable */
  require("smooth-scroll")('a[href*="#"]', {
    speed: "500",
    easing: "easeInOutQuad",
    updateURL: false
  });
  /* tslint:enable */
}

export default function Home() {
  return (
    <Layout
      title="Misesova akademie"
      description="Již od roku 1996 pořádáme letní školu, kde se studenty debatujeme o ekonomice, politice, historii, filosofii anebo o čemkoliv, co se naskytne. Letní škola se jmenuje Misesova akademie Liberálního institutu, zkráceně MALI."
    >
      <JumbotronSection />
      <SectionAbout />
      <EventSpeakers />
      <EventPrograms />
      <SectionDocuments />
      <Graduates />
      <PartnersSection />
      <HistorySection />
    </Layout>
  )
}
